<template>
  <div class="shipping-price-container layout-padding">
    <PageTitle
      class="my-8"
      :title="content.shippingPrices"
      :childOne="content.shippingPrices"
      routeOne="/shippingprices"
    />
    <div class="prices-table my-8">
      <div class="grid grid-cols-12 px-4 prices-table-header">
        <div class="col-span-1 px-3">#</div>
        <div class="smd:col-span-5 col-span-6 px-3">{{ content.city }}</div>
        <!-- <div class="col-span-4 smd:flex hidden px-3">{{ content.area }}</div> -->
        <div class="col-span-5 smd:col-span-2 px-3">{{ content.price }}</div>
      </div>
      <div
        class="table-data grid grid-cols-12 px-4"
        v-for="(city, index) in cities"
        :key="city"
      >
        <div class="col-span-1 px-3">{{ index + 1 }}</div>
        <div class="smd:col-span-5 col-span-6 px-3">
          <span class="lora-bold">{{ city.name }} </span>
          <!-- <span class="smd:hidden">{{city.name}}</span> -->
        </div>
        <!-- <div class="col-span-4 smd:flex hidden px-3">
          Lorem ipsum dolor sit amet
        </div> -->
        <div class="col-span-5 smd:col-span-2 px-3 whitespace-nowrap">
          {{ format(city.price) }} {{ content.iqd }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "/src/assets/numberFormat";
export default {
  inject: ["content"],
  computed: {
    cities() {
      return this.$store.getters["cities/cities"];
    },
  },
  methods: {
    format(number) {
      return format(number);
    },
  },
};
</script>